/* eslint max-len: off */

import { nollningsYear } from '../utils/date';

import Cover from '../components/Cover';
import Split from '../components/Split';

import { covers } from '../data/covers';
import { SpiikList, SpiikListItem } from '../components/SpiikList';
import SpiikSwiper from '../components/SpiikSwiper';

const { introduction: cover } = covers;

const intro = {
  imgUrl: './ovveinvigning2.jpg',
  title: `Nollning/introduktion ${nollningsYear()}`,
};

const Introduction = () => (
  <>
    <section>
      <Cover imgUrl={cover.imgUrl} title={cover.title}>
        {cover.text}
      </Cover>
    </section>
    <section>
      <div className="container">
        <Split>
          <div>
            <h2>{intro.title}</h2>
            <p>
              Måndag den 26 augusti klockan 10:00 samlas nya studenter i Fullriggaren, sal Ma135, på campus (
              <a className="styled-link" target="_blank" href="https://www.google.com/maps/d/edit?mid=1dS2rtd2ONvIXSTkN91HKJHtLhdTFE-0&usp=sharing" rel="noreferrer">se karta</a>
              ). Där kommer ni få ett välkomnande av era programansvariga, träffa en del av era lärare och ta del av relevant information inför studiestarten. Ordförande och sexmästare i SPIIK kommer också dyka upp för att välkomna er till studentlivet, presentera sig själva samt säga några ord om den kommande introduktionen.
            </p>
            <br />
            <p>
              När ni är klara i Fullriggaren går ni ut och letar upp oss i röda overaller om ni vill delta på introduktionen med oss. Tillsammans beger vi oss till Marmor, sal Ra2135K. Vi är där en liten stund innan vi drar ut på rundvandring i stan. Här kan ni se en
              <a className="styled-link" target="_blank" href="https://www.google.com/maps/d/edit?mid=1dS2rtd2ONvIXSTkN91HKJHtLhdTFE-0&usp=sharing" rel="noreferrer"> karta </a>
              över ställen vi kanske kommer att besöka under introduktionens gång.
            </p>
            <br />
            <p>
              På vårt
              {' '}
              <a className="styled-link" target="_blank" href="https://fb.me/e/4PrCz8J5q" rel="noreferrer">Facebook-event</a>
              {' '}
              kommer det finnas ett schema för hela introduktionen. Det är även där vi kommer lägga ut viktig information eller ändringar så klicka gärna i att ni kommer så ni inte missar någon viktig info!
            </p>
          </div>
          <img src={intro.imgUrl} alt="placeholder" />
        </Split>
        <br />
        <Split>
          <div>
            <h3>
              Lite bra begrepp att känna till inför nollningen
            </h3>
            <SpiikList>
              <SpiikListItem>
                <b>STAB</b>
                {' '}
                - huvudansvar för nollningen
              </SpiikListItem>
              <SpiikListItem>
                <b>Fadder</b>
                {' '}
                - finns där för att göra din nollning rolig, hjälpa dig med frågor eller andra funderingar och stötta staben
              </SpiikListItem>
              <SpiikListItem>
                <b>KSP</b>
                {' '}
                - ofta säger vi KSP och då menar vi Kalmarsundsparken (parken är stor, vi är i området där det finns en glasskiosk och en lång brygga)
              </SpiikListItem>
              <SpiikListItem>
                <b>Max</b>
                {' '}
                - hamburgerrestaurang MAX på Trädgårdsgatan 17 (detta är en vanlig uppsamlingsplats)
              </SpiikListItem>
              <SpiikListItem>
                <b>Smålle</b>
                {' '}
                - lokal i källaren på Smålandsgatan 4A
              </SpiikListItem>
              <SpiikListItem>
                <b>Kårhus</b>
                {' '}
                - ny studentpub på Skeppsbrogatan 30 vid namn Lék-Stugan
              </SpiikListItem>
              <SpiikListItem>
                <b>Ovve</b>
                {' '}
                - står för overall och den ska såklart vara
                {' '}
                <span className="text-red text-accent">RÖD</span>
                !
              </SpiikListItem>
              <SpiikListItem>
                <b>Nercabbat</b>
                {' '}
                - när overall enbart bärs upp till midjan och resten hänger ner
              </SpiikListItem>
              <SpiikListItem>
                <b>Sittning</b>
                {' '}
                - en middag med spex eller underhållning, ibland ingår även ett tema
              </SpiikListItem>
              <SpiikListItem>
                <b>Spex</b>
                {' '}
                - ett uppträdande som oftast framförs på sittningar
              </SpiikListItem>
              <SpiikListItem>
                <b>SPIIK-nykter</b>
                {' '}
                - på alla SPIIKs event kommer det finnas nyktert ansvariga och dessa kommer då bära gula västar
              </SpiikListItem>
            </SpiikList>
          </div>
          <div>
            <h3>
              Nollning kostnader
            </h3>
            <p>
              Det kostar lite att vara med på nollningen. Det som kostar är bland annat mat på sittningarna, hyror när vi hyr lokaler och medlemskap.
            </p>
            <hr />
            <p>Det olika paketen som finns är:</p>
            <SpiikList>
              Det
              {' '}
              <strong>BRA</strong>
              {' '}
              paketet. Här ingår medlemskap, och programmärke.
              <SpiikList>
                <SpiikListItem>350kr (1 års medlemskap)</SpiikListItem>
                <SpiikListItem>400kr (2 års medlemskap)</SpiikListItem>
                <SpiikListItem>450kr (3 års medlemskap)</SpiikListItem>
              </SpiikList>
              <br />
              Det
              {' '}
              <strong>BÄTTRE</strong>
              {' '}
              paketet. Här ingår medlemskap, programmärke, och en biljett till fulsittningen.
              <SpiikList>
                <SpiikListItem>500kr (1 års medlemskap)</SpiikListItem>
                <SpiikListItem>550kr (2 års medlemskap)</SpiikListItem>
                <SpiikListItem>600kr (3 års medlemskap)</SpiikListItem>
              </SpiikList>
              <br />
              Det
              {' '}
              <strong>BÄSTA</strong>
              {' '}
              paketet. Här ingår medlemskap, programmärke, och en biljett till bägge fulsittningen och finsittningen.
              <SpiikList>
                <SpiikListItem>700kr (1 års medlemskap)</SpiikListItem>
                <SpiikListItem>750kr (2 års medlemskap)</SpiikListItem>
                <SpiikListItem>800kr (3 års medlemskap)</SpiikListItem>
              </SpiikList>
            </SpiikList>
            <p>
              Vi skulle också rekommendera att ha lite extra pengar på sparkontot då vi ofta går och käkar snabbmat om dagarna. Man får absolut ta med egen mat och det kommer finnas tid att käka. Tillgång till mikro kan dock inte garanteras när vi befinner oss utanför skolans lokaler.
            </p>
          </div>
        </Split>
        <br />
        <Split>
          <div>
            <h3>
              Regler och allmänna tips till nollningen
            </h3>
            <SpiikList>
              <SpiikListItem>
                Totalförbud mot droger/narkotika.
              </SpiikListItem>
              <SpiikListItem>
                Ha på er nollebanden HELA TIDEN. Detta är för att SPIIK och andra föreningar ska kunna identifiera våra nollor så lätt som möjligt.
              </SpiikListItem>
              <SpiikListItem>
                Håll koll på Messenger och Facebook. Det är där relevant, viktig och uppdaterad information läggs upp.
              </SpiikListItem>
              <SpiikListItem>
                Under nollningen dricker ni precis själva det ni vill, alltså juice, mjölk, läsk, vatten, allt är lika bra och morgondagen blir säkert lite bättre! OM du väljer att dricka alkohol är detta såklart INTE lov under nyktert event (vilket event det är kommer stå i schemat).
              </SpiikListItem>
              <SpiikListItem>
                Det kan förekomma att det fotograferas, om man sätter upp handen eller säger till den som fotograferar så kommer hen att radera bilden.
              </SpiikListItem>
              <SpiikListItem>
                Ta med kläder efter väder. Denna är extra viktig i år då vi kommer att vara ute en del. Det gäller att ha kläder mot kyla samt att skydda sig mot solen med solskyddsfaktor.
              </SpiikListItem>
              <SpiikListItem>
                Ha lite extra pengar undanstoppade för nollningen (se mer information under rubriken Nollning kostnader ovan).
              </SpiikListItem>
              <SpiikListItem>
                Vi rekommenderar att dricka ordentligt med vatten om det är varmt ute.
              </SpiikListItem>
              <SpiikListItem>
                Alla aktiviteter är frivilliga under nollningen och du kommer aldrig tvingas till något. Däremot är det alltid uppskattat att vara med och heja på sin förening om man inte själv vill delta i något!
              </SpiikListItem>
              <SpiikListItem>
                Använd faddrarna, de är där för att hjälpa dig i den mån som de kan.
              </SpiikListItem>
              <SpiikListItem>
                Faddrar får EJ genomföra någon form av sexuellt närmande eller inleda någon relation av romantisk eller sexuell karaktär gentemot någon utav de nyantagna studenterna under indtroduktionens gång.
              </SpiikListItem>
            </SpiikList>
          </div>
          <div>
            <h3>SPIIK Kartan</h3>
            <iframe id="spiik-map" title="spiik_map" src="https://www.google.com/maps/d/embed?mid=1_1o4t-ET9iWjq4iadIJxW6qPcVb1xoc&ehbc=2E312F" />
          </div>
        </Split>
        <br />
        <div className="text-center">
          {/* TODO: Remove inline styles when possible */}
          <b style={{ fontSize: '2rem' }}>Vi i SPIIK vill önska er varmt välkomna till Kalmar och introduktionen med oss!</b>
        </div>
      </div>
    </section>
    <section>
      <div className="container text-center">
        <h2>
          {/* `Nollning ${nollningsYear() - 1}` */}
          {2023}
        </h2>
        <SpiikSwiper />
      </div>
    </section>
  </>
);

export default Introduction;
