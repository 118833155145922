import { FC } from 'react';
import { v4 as uuid } from 'uuid';
import Accommodation from '../pages/Accommodation';
import BoardMembers from '../pages/BoardMembers';
import Bylaws from '../pages/Bylaws';
import Education from '../pages/Education';
import Introduction from '../pages/Introduction';
import Membership from '../pages/Membership';
import Overall from '../pages/Overall';
import Sponsors from '../pages/Sponsors';
import Start from '../pages/Start';

type Link = {
  id: string;
  exact: boolean;
  path: string;
  title: string;
  component: FC;
};

const links: Link[] = [
  {
    id: uuid(),
    exact: true,
    path: '/',
    title: 'Start',
    component: Start,
  },
  {
    id: uuid(),
    exact: false,
    path: '/styrelsen',
    title: 'Styrelsen',
    component: BoardMembers,
  },
  {
    id: uuid(),
    exact: false,
    path: '/utbildningar',
    title: 'Utbildningar',
    component: Education,
  },
  {
    id: uuid(),
    exact: false,
    path: '/introduktion',
    title: 'Introduktion',
    component: Introduction,
  },
  {
    id: uuid(),
    exact: false,
    path: '/boende',
    title: 'Boende',
    component: Accommodation,
  },
  {
    id: uuid(),
    exact: false,
    path: '/medlemskap',
    title: 'Medlemskap',
    component: Membership,
  },
  {
    id: uuid(),
    exact: false,
    path: '/overall',
    title: 'Overall',
    component: Overall,
  },
  {
    id: uuid(),
    exact: false,
    path: '/sponsorer',
    title: 'Sponsorer',
    component: Sponsors,
  },
  {
    id: uuid(),
    exact: false,
    path: '/stadgar',
    title: 'Stadgar',
    component: Bylaws,
  },
];

export default links;
