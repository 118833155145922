import { v4 as uuid } from 'uuid';

type Program = {
  id: string;
  title: string;
  hp: string;
  description: string;
  imgUrl: string;
  imgAlt: string;
  url: string;
};

const programs: Program[] = [
  {
    id: uuid(),
    title: 'Mjukvaruteknik, högskoleingenjör',
    hp: '180 hp',
    description:
      'Vill du utveckla IT-system för inbyggda system i bilar eller intelligenta robotar? Vill du specialisera dig på uppkopplade enheter? Detta högskoleingenjörsprogram i mjukvaruteknik har fokus på Internet of Things (IoT) och kommer förbereda dig för hela arbetsmarknaden inom IT-området men med fördjupning inom IoT. Framtiden är spännande och behovet av ingenjörer med kompetens inom både mjukvara och inbyggd hårdvara är stort. Programmets mål är att utbilda ingenjörer som kan arbeta med utveckling av olika typer av mjukvarusystem och uppkopplade enheter. Det är en 3-årig utbildning med teknikindustrin som målgrupp, i synnerhet större företag med stora projekt som löper under lång tid där organisation, systematik och struktur är viktigt. Programmets fokus är mjukvaruteknik. Studenterna blir duktiga systemutvecklare med goda kunskaper inom ett brett område, programmering, datorteknik, maskininlärning, inbyggd hårdvara, databaser, webbteknik och nätverk. Programmets speciella inriktning är Internet of Things med lika fokus på hur olika hårdvarukomponenter (t.ex. sensorer) kan kopplas till internet som serverarkitekturer och tekniska lösningar på serversidan. En ingenjör förväntas också ha en bred teknisk kompetens. IT-kurserna kompletteras därför med kurser i matematik, fysik och industriell ekonomi. Arbetsmarknaden för ingenjörer med IT-kompetens är mycket god och störst efterfrågan är inom nya teknologiområden så som molntjänster, tillämpad maskininlärning och Internet of Things.',
    imgUrl: './lnu.png',
    imgAlt: 'linnéuniversitetet',
    url: 'https://lnu.se/program/mjukvaruteknik-hogskoleingenjor/kalmar-ht/',
  },
  {
    id: uuid(),
    title: 'Webbprogrammering',
    hp: '180 hp',
    description:
      'Som webbprogrammerare kommer du att vidareutveckla ditt intresse för webben och programmering i en utvecklande miljö med spännande möjligheter. Vill du lära dig programmera för webben? Vill du få kunskap om moderna och kommande tekniker som används vid utveckling av webbplatser? Tycker du dessutom att det är viktigt att du som student blir sedd och uppmärksammad? Då kommer du att trivas hos oss! Vi brinner för webbutveckling och för att utbilda dig i, och med hjälp av, den senaste tekniken. Du kommer att uppleva närhet till lärare och studenter oavsett om du väljer att läsa programmet på campus eller på distans. Studerar du på distans finns inget krav på deltagande i Kalmar. Under utbildningen läser du kurser där programmering är i fokus. Du kommer att skapa webbapplikationer för olika plattformar med arbetssätt och metoder som är aktuella i branschen. Kodkvalitet, säkerhet, testning och entreprenörskap genomsyrar utbildningen som bedrivs i samarbete med näringslivet. Utbildningen kan byggas på med ett tredje fördjupningsår inom datavetenskap som förbereder dig för studier på magister- och masternivå, se programmet Påbyggnadsutbildning inom datavetenskap, kandidatexamen.',
    imgUrl: './lnu.png',
    imgAlt: 'linnéuniversitetet',
    url: 'https://lnu.se/program/webbprogrammerare-ngweb/kalmar-ht/',
  },
  {
    id: uuid(),
    title: 'Interaktionsdesign',
    hp: '180 hp',
    description:
      'Som interaktionsdesigner arbetar du inom ett aktuellt och snabbt växande område där teknik och användarvänlighet alltid är i fokus. Du skapar förutsättningarna för att vi människor ska kunna leva och arbeta med tekniken i vår vardag. En interaktionsdesigner förstår, utformar och analyserar samspelet mellan människa och teknik. Det kan handla om webbsidor, programvaror eller produkter. Han/hon har även en god förmåga att se hur människor använder tekniken, både professionellt och till vardags, samt hur den påverkar våra liv. Ambitionen är att tillgodose de behov och svagheter vi människor har genom att utforma gränssnitt som stöder oss i vårt arbete. För att förstå detta samspel studerar du människa/dator-interaktion, användbarhetslära och kognitionslära i samma utsträckning som grafisk formgivning, design och metoder för att analysera teknikanvändning. Du får grundläggande kunskaper inom programmering och produktutveckling; från mer analoga metoder som papper, penna och lera till mer tekniska som digital bildbehandling i 2D och 3D. Du får även fördjupade kunskaper inom användaranpassning av olika interaktiva system, samt metoder för att utvärdera, analysera och grafiskt visualisera hur vi samverkar med tekniken runt oss. Utbildningen är starkt tvärvetenskaplig och du utvecklas personligen med entreprenörskap, branschförståelse och innovativt tänkande. Du kan välja att läsa i Kalmar eller hemifrån på distans.',
    imgUrl: './lnu.png',
    imgAlt: 'linnéuniversitetet',
    url: 'https://lnu.se/program/interaktionsdesigner/kalmar-ht/',
  },
];

export default programs;
