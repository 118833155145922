import React from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
    status: boolean,
    onClick: React.MouseEventHandler<any>,
    exact: boolean,
    to: string,
    children: React.ReactChild
}

const CustomNavLink = ({
  status, onClick, exact, to, children,
}: Props) => (
  <li className="nav-link">
    <NavLink
      onClick={onClick}
      exact={exact}
      to={to}
      className={`${status ? 'show-link' : 'hide-link'}`}
      activeClassName="active-link"
    >
      {children}
    </NavLink>
  </li>
);

export default CustomNavLink;
