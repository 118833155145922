import Cover from '../components/Cover';

import { covers } from '../data/covers';

const { accomodation: cover } = covers;

const rikshemUrl = 'https://minasidor.rikshem.se/ledigt/studentlagenhet?objectgroup=&selectedarea=AREAKALMAR&fbclid=IwAR0tbbiZywXTUUN9Nk5EQhi-rzQWOlu3N1BMZ10zbVIVWraCuRf2yYEbmV8';

const Accommodation = () => (
  <>
    <section>
      <Cover imgUrl={cover.imgUrl} title={cover.title}>
        {cover.text}
        <br />
        <a className="styled-link" target="_blank" aria-label="linnéuniversitetet" href="https://lnu.se/utbildning/studera-vid-linneuniversitetet/boende/" rel="noreferrer">
          Linnéuniversitetets informationsida
        </a>
        <br />
        <a className="styled-link" target="_blank" aria-label="linnek" href="https://linnek.se/bostadsportal" rel="noreferrer">
          Linnékårens bostadsportal
        </a>
        <br />
        <a className="styled-link" target="_blank" aria-label="kalmar kommun" href="https://kalmar.se/bygga-bo-och-miljo/bostader/hitta-bostad.html?fbclid=IwAR0hg0vxKCe2PjpBCP0PQDF4-DBchNVgFOj0vXP6v5WHhNVEdFDcwFt_OdQ" rel="noreferrer">
          Kalmar Kommun
        </a>
        <br />
        <a className="styled-link" target="_blank" aria-label="kalmarhem" href="https://kalmarhem.se" rel="noreferrer">
          Kalmarhem
        </a>
        <br />
        <a className="styled-link" target="_blank" aria-label="rikshem" href={rikshemUrl} rel="noreferrer">
          Rikshem
        </a>
      </Cover>
    </section>
  </>
);

export default Accommodation;
