import React from 'react';
import { GiNails } from 'react-icons/gi';
import styled from 'styled-components';

export const SpiikList = styled.ul`
    list-style-type: none;
    margin-left: 1em;
    padding-left: 0;
`;

const StyledListItem = styled.li`
`;

const StyledIconSpan = styled.span`
    padding-right: 0.5em;
`;

const StyledTextSpan = styled.span`
    vertical-align: text-top;
`;

export const SpiikListItem = ({ children }: {children: React.ReactChild | React.ReactChild []}) => (
  <StyledListItem>
    <StyledIconSpan><GiNails style={{ verticalAlign: 'middle' }} /></StyledIconSpan>
    <StyledTextSpan>{children}</StyledTextSpan>
  </StyledListItem>
);
