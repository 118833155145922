import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Footer from './components/Footer';
import Header from './components/Header';
import links from './data/links';

function App() {
  return (
    <Router>
      <div className="bg-light">
        <Header />
        <div className="wrapper">
          <main>
            <Switch>
              {links.map((l) => (
                <Route
                  key={l.id}
                  exact={l.exact}
                  path={l.path}
                  component={l.component}
                />
              ))}
            </Switch>
          </main>
          <Footer />
        </div>
      </div>
    </Router>
  );
}

export default App;
