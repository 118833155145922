// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/swiper.min.css';
import 'swiper/modules/pagination/pagination.min.css';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/autoplay/autoplay.min.css';

// import custom css
import './SpiikSwiper.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper';

// Images helper
import images from '../utils/images';

export default function App() {
  return (
    <>
      <Swiper
        spaceBetween={30}
        centeredSlides
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
        {images().map((img) => <SwiperSlide><img src={img} alt="" /></SwiperSlide>)}
      </Swiper>
    </>
  );
}
