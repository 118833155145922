import { financialYear } from '../utils/date';
import { board, BoardMember, Titles } from './board';

export type Cover = {
  imgUrl: string;
  title: string;
  text: string;
};

// Find SSUA name dynamically
const nameSSUA = board.find((b: BoardMember) => b.title === Titles.SSUA)!.name;

// Financial Year
const fYear = financialYear();

export const covers: Record<string, Cover> = {
  board: {
    imgUrl: './spiik_shield.jpg',
    title: `Styrelsen ${fYear}/${fYear + 1}`,
    text: 'Styrelsen består av 9 teknikstudenter. Tillsammans arbetar vi i styrelsen med att främja sammanhållningen hos studenterna, tillvaratar medlemmarnas gemensamma angelägenheter och företräda dem i studierelaterade frågor, utveckla kontakter med näringslivet samt anordna olika event som främjar medlemmarnas intressen. Varje år väljs en ny styrelse där det är ni medlemmar som under vårt valmöte bestämmer vilka som ska sitta i styrelsen. Kontakta oss: ',
  },
  accomodation: {
    imgUrl: './kalmar_sign.jpg',
    title: 'Boende',
    text: 'Har du bilvit antagen till campus och behöver ett boende? Super! Här nedan hittar du en lista med exempel på olika hyresvärdar för studenter i Kalmar.',
  },
  introduction: {
    imgUrl: './spiik_flag.jpg',
    title: 'Introduktion',
    text: 'Nollningen aka introduktionen för nya studenter börjar en vecka innan terminsstart och pågår under två veckor. Varje dag kommer det finnas möjlighet för dig att delta på olika event som anordnas av SPIIK och Linnékåren där du får lära känna nya människor, träffa dina kommande klasskamrater, lära känna Kalmar och känna på studentlivet. Introduktionen är ett sätt att ge dig en bra start inför dina kommande år på Linnéuniversitetet, oavsett om du är campus student eller distansstudent.',
  },
  education: {
    imgUrl: './computer.jpg',
    title: 'Utbildningar',
    text: 'De utbildningar som tillhör fakulteten teknik är mjukvaruteknik, webbprogrammering och interaktionsdesign. Nedan kan du läsa om de olika utbildningarna eller så går det att besöka lnu.se för mer information.',
  },
  educationHelp: {
    imgUrl: './det_loser_sig.jpg',
    title: 'Vi hjälper dig!',
    text: `Har det uppstått problem med en kurs eller tycker du att du har blivit felbehandlad av en lärare? Tveka då inte att höra av dig och skicka ett mail till våran SSUA, ${nameSSUA}! Han kan visa dig vart du ska vända dig för hjälp.\r\n`,
  },
  membership: {
    imgUrl: './meeting.jpg',
    title: 'Medlemskap',
    text: 'Vad innebär det att vara medlem? Först och främst innebär det att du som medlem har närvaro-, yttrande-, yrkande-, samt rösträtt vid stormöte. Det ger dig möjlighet att aktivt påverka föreningens arbete. Som medlem är du alltid välkommen att engagera dig i föreningens arbete. Det kan till exempel vara att sitta i ett utskott, hjälpa till med projekt eller agera fadder under introduktionsveckorna. Det finns alltid behov av engagerade människor och alla insatser, stora som små är alltid varmt välkomna. Tillbaks får du erfarenhet och kul umgänge!',
  },
  overall: {
    imgUrl: './overall.jpg',
    title: 'Overall',
    text: 'Som du kommer märka så springer det runt en massa folk i overaller av diverse färg, fyllda med en himla massa tygmärken. Vad i hela friden är det som händer? Men se, svaret är lätt: Det är studentoveraller det handlar om!',
  },
};
