import Cover from '../components/Cover';
import { SpiikList, SpiikListItem } from '../components/SpiikList';
import { covers } from '../data/covers';

const { membership: cover } = covers;

const process = {
  one: 'Skicka en swish till 123 27 179 57 Studentföreningen Prima Ingenjörer och skriv ”Medlemskap” som meddelande.',
  two: { text: 'Fylla i detta formulär: ', link: 'https://forms.gle/YpzBwUiKhje7BnBq7' },
  three: 'Nu är du medlem!',
};

const Membership = () => (
  <>
    <section>
      <Cover imgUrl={cover.imgUrl} title={cover.title}>
        {cover.text}
      </Cover>
    </section>
    <div className="container container--narrow">
      <h2>Hur blir jag medlem?</h2>
      <p>Vill du bli medlem i SPIIK? Då ska du titta hit!</p>
      <hr />
      <p>Det finns tre olika typer av medlemskap:</p>
      <SpiikList>
        <SpiikListItem>
          Campusstudenter
          <SpiikList>
            <SpiikListItem>
              1 år för 100kr
            </SpiikListItem>
            <SpiikListItem>
              2 år för 150kr
            </SpiikListItem>
            <SpiikListItem>
              3 år för 200kr
            </SpiikListItem>
          </SpiikList>
        </SpiikListItem>
        <SpiikListItem>Distansstudenter - 1 år för 50kr</SpiikListItem>
        <SpiikListItem>Stödmedlemskap - 1 år från 100kr</SpiikListItem>
      </SpiikList>

      <hr />
      <p>Du blir enkelt medlem genom att …</p>
      <ol>
        <li>{process.one}</li>
        <li>
          {process.two.text}
          <a className="styled-link" target="_blank" href={process.two.link} rel="noreferrer">{process.two.link}</a>
        </li>
        <li>{process.three}</li>
      </ol>

    </div>
  </>
);

export default Membership;
