import { ReactChild } from 'react';
import styled from 'styled-components';

type StyledProps = { imgUrl: string };

type ReactProps = {
  imgUrl: string,
  title: string,
  children: ReactChild | ReactChild[]
};

const StyledCover = styled.div<StyledProps>`
  background: url(${(props) => props.imgUrl});
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  min-height: 30em;
`;

const StyledTextBox = styled.div`
  width: 100%;
  padding: 1em 2em;
  background-color: rgba(255, 255, 255, 0.85);
  @media (min-width: 55em) {
    width: 40%;
  }
`;

const StyledH2 = styled.h2`
  padding-bottom: 1em;
`;

const StyledParagraph = styled.p``;

const Cover = ({ imgUrl, title, children }: ReactProps) => (
  <StyledCover imgUrl={imgUrl}>
    <StyledTextBox>
      <StyledH2>{title}</StyledH2>
      <StyledParagraph>{children}</StyledParagraph>
    </StyledTextBox>
  </StyledCover>
);

export default Cover;
