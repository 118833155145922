import Split from '../components/Split';
import { sponsors } from '../data/sponsors';

const Sponsors = () => (
  <div className="container">
    <section className="text-center">
      <h2>Sponsorer</h2>
      <p>Här kan du hitta våra fantastiska sponsorer! Kika in dem via länkarna här nedanför!</p>
    </section>
    {sponsors.map((s) => (
      <section key={s.id}>
        <Split two3070>
          <img src={s.imgUrl} alt={s.imgAlt} />
          <div>
            <p>{s.info}</p>
            <a className="styled-link" target="_blank" href={s.url} rel="noreferrer">{s.urlTxt}</a>
          </div>
        </Split>
      </section>
    ))}
  </div>
);

export default Sponsors;
