import { v4 as uuid } from 'uuid';
import Cover from '../components/Cover';
import Split from '../components/Split';

import { BoardMember, board, boardMail } from '../data/board';
import { covers } from '../data/covers';

const { board: cover } = covers;

// processed data, 2 board members per array in 2d array.
const data: { id: string, bms: BoardMember[] }[] = [];
while (board.length) {
  data.push({
    id: uuid(),
    bms: board.splice(0, 2),
  });
}

const BoardMembers = () => (
  <>
    <section>
      <Cover imgUrl={cover.imgUrl} title={cover.title}>
        {cover.text}
        <span>
          <a className="styled-link" href={`mailto:${boardMail}`}>{boardMail}</a>
        </span>
      </Cover>
    </section>
    {data.map(((section) => (
      <section key={section.id}>
        <div className="container">
          <Split>
            {section.bms.map((bm) => (
              <Split key={bm.id}>
                <img src={bm.img} alt={bm.alt} />
                <div>
                  <h2>{bm.name}</h2>
                  <h3>{bm.title}</h3>
                  <a className="styled-link" aria-label={bm.title} href={`mailto:${bm.mail}`}>{bm.mail}</a>
                  <br />
                  <strong>Studerar:</strong>
                  <p>{bm.program}</p>
                  <strong>Meddelande:</strong>
                  <p>{bm.message}</p>
                  <strong>Citat:</strong>
                  <p>{bm.quote}</p>
                  <strong>Merit:</strong>
                  <p>{bm.merit}</p>
                  <strong>Favoritspel:</strong>
                  <p>{bm.game}</p>
                </div>
              </Split>
            ))}
          </Split>
        </div>
      </section>
    )))}
  </>
);

export default BoardMembers;
