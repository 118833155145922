/* eslint max-len: off */

import Cover from '../components/Cover';
import Split from '../components/Split';
import { covers } from '../data/covers';

const { overall: cover } = covers;

const sections = {
  one: {
    title: 'Ovve',
    text: 'Med studentoverallen visar man sin omgivning vilken institution man tillhör och ibland även vilket program man går. I SPIIK har vi en röd overall med en logga bestående av kugghjul, spikar och lagerblad på ryggen. Overallen är studentens käraste ägodel och har därför lite regler kring sig.',
  },
  two: {
    title: 'Vart kan jag köpa min ovve?',
    text: 'Overallen går att köpa på SPIIKs kontor i Magna på Universitetskajen. Försäljning sker i samband med overallsinvigningen som äger rum någon gång under höstterminen efter introduktionsveckorna. På kontoret sker även försäljning av märken att pynta ovven med. Mer information kommer ut när ett datum är satt.',
  },
  three: {
    title: 'Ovveinvigning',
    text: 'Innan overallen kan pyntas behöver den invigas. Detta görs under SPIIKs årliga ovveinvigning som sker i dagarna efter introduktionsveckorna. På våra sociala medier kommer mer information att komma ut när invigningen börjar närma sig.',
  },
};

const { one, two, three } = sections;

const Overall = () => (
  <>
    <section>
      <Cover imgUrl={cover.imgUrl} title={cover.title}>
        {cover.text}
      </Cover>
    </section>
    <div className="container">
      <section>
        <Split>
          <div>
            <h2>{one.title}</h2>
            <div>
              <p>{one.text}</p>
              <ol>
                <li>Ska som minst prydas med föreningsmärket och programmärket.</li>
                <li>
                  Tvätt av overall får inte ske med undantag för:
                  <ol type="a">
                    <li>Innan overallsinvigningen (dvs innan man har använt den för första gången)</li>
                    <li>Om man själv befinner sig i overallen.</li>
                  </ol>
                </li>
                <li>Får inte användas innan overallsinvigningen.</li>
              </ol>
            </div>
          </div>
          <img src="./ovve1.jpg" alt="ok stamp" />
        </Split>
      </section>
      <section>
        <Split>
          <img src="./ovve4.jpg" alt="kullerbytta" />
          <div>
            <h2>{two.title}</h2>
            <p style={{ marginBottom: '3em' }}>{two.text}</p>
            <h2>{three.title}</h2>
            <p>{three.text}</p>
          </div>
        </Split>
      </section>
      <section>
        <Split>
          <img src="./ovve6.jpg" alt="" />
          <img src="./ovve3.jpg" alt="" />
        </Split>
      </section>
      <section>
        <Split>
          <img src="./ovve2.jpg" alt="" />
          <img src="./ovve5.jpg" alt="" />
        </Split>
      </section>
    </div>
  </>
);

export default Overall;
