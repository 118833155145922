/* eslint max-len: off */

const Bylaws = () => (
  <section>
    <div className="container container--narrow bylaws">
      <h2 id="-1-namn">§ 1 NAMN</h2>
      <p>Föreningens namn är Studentföreningen Prima Ingenjörer I Kalmar (SPIIK), och har sitt säte i Kalmar. SPIIK är en religiöst och partipolitiskt obunden förening.</p>
      <h2 id="-2-syfte">§ 2 SYFTE</h2>
      <p>SPIIK skall representera och arbeta för medlemmar studerande på en teknisk högskole- eller universitets-utbildning i Kalmar. SPIIK skall tillvarata medlemmarnas gemensamma angelägenheter och företräda dem beträffande studierelaterade frågor. SPIIK skall främja sammanhållningen och kamratskapen mellan studenterna på institutionen för teknik. SPIIK skall utveckla de tekniska programmens kontakter med näringslivet samt den offentliga förvaltningen. SPIIK skall främja medlemmarnas intresse i tekniska frågor.</p>
      <h2 id="-3-verksamhets-r">§ 3 VERKSAMHETSÅR</h2>
      <p>Verksamhetsåret omfattar tiden mellan två årsmöten. Räkenskapsåret följer kalenderåret.</p>
      <h2 id="-4-organisation">§ 4 ORGANISATION</h2>
      <p>SPIIKs verksamhet utövas av: Stormöte Styrelse Utskott/Arbetsgrupper Valberedning Föreningens styrelse svarar inför föreningsmöten och årsmötet. Årsmötet utgör SPIIKs högsta beslutande organ. Respektive utskott/arbetsgrupp ansvarar inom sitt verksamhetsområde direkt mot styrelsen.</p>
      <h2 id="-5-medlemskap">§ 5 MEDLEMSKAP</h2>
      <h3 id="-5-1-medlemsskyldigheter">§ 5.1 Medlemsskyldigheter</h3>
      <p>Aktiv/Passiv medlem är skyldig att betala en av föreningens fastställd medlemsavgift. Medlem är skyldig att följa av styrelse och stormöte fastlagda beslut gällande föreningens verksamhet.</p>
      <h3 id="-5-2-aktivt-medlemskap">§ 5.2 Aktivt medlemskap</h3>
      <p>Berättigad till att lösa aktivt medlemskap är den som studerar på någon av de tekniska högskole- eller universitets- utbildningar som ges i Kalmar. Aktiv medlem äger närvaro-, yttrande-, yrkande- samt rösträtt vid stormöte. Aktiv medlem har rätt att få motion behandlad av stormöte. Närvarande aktiv medlem har rätt att reservera sig mot stormötes beslut. Röstning får ej ske via ombud.</p>
      <h3 id="-5-3-passivt-medlemskap">§ 5.3 Passivt medlemskap</h3>
      <p>Berättig till att lösa passivt medlemskap är den som vill arbeta för, och/eller stödja föreningens syften. Passiv medlem äger närvaro-, yttrande- samt yrkanderätt vid stormöte. Passiv medlem äger aldrig rösträtt. Passiv medlem har rätt att få motion behandlad av stormöte. Närvarande passiv medlem har rätt att göra protokollsanteckning vid stormötes beslut.</p>
      <h3 id="-5-4-hedersmedlemskap">§ 5.4 Hedersmedlemskap</h3>
      <p>Till hedersmedlem kan sådan person utses som på ett förtjänstfullt sätt stöttat föreningen och dess verksamhet. Stormöte äger rätt att på styrelsens eller enskild medlems förslag välja in person som hedersmedlem i föreningen. Stormötets beslut skall fattas med minst två tredjedelars majoritet. Hedersmedlem äger närvaro- och yttranderätt vid stormöte. Hedersmedlemskap gäller naturligtvis livet ut såvida hedersmedlemmen inte allvarligt skadar föreningens anseende och stormöte beslutar annat. Hedersmedlem kan givetvis själv begära utträde ur föreningen.</p>
      <h3 id="-5-5-uttr-de">§ 5.5 Utträde</h3>
      <p>Begäran om utträde av medlem skall ske skriftligen till styrelsen som beviljar detta vid möte. Återbetalning av erlagd medlemsavgift sker ej.</p>
      <h3 id="-5-6-uteslutning">§ 5.6 Uteslutning</h3>
      <p>Uteslutning av medlem kan ske på beslut av styrelse eller stormöte om medlem grovt har brutit mot de ålägganden medlemmen har. Återbetalning av erlagd medlemsavgift kan av styrelsen beviljas om så begärs.</p>
      <h3 id="-5-7-ans-kan-om-aktivt-medlemskap">§ 5.7 Ansökan om aktivt medlemskap</h3>
      <p>Om man är passiv medlem, kan man ansöka om aktivt medlemskap om man vill ha rösträtt i föreningen. Man ansöker då till valberedningen senast en vecka innan stormöte. Ärendet tas sen upp på stormötet där de aktiva medlemmarna får rösta, med enkel majoritet, om personen i fråga ska få beslutsrätt i SPIIK. Beslutet gäller ett år framåt och man måste sedan ansöka på nytt.</p>
      <h2 id="-6-dokument">§ 6 DOKUMENT</h2>
      <h3 id="-6-1-motioner">§ 6.1 Motioner</h3>
      <p>Motioner till stormötet ska vara föreningens styrelse tillhanda senast en vecka före stormötesdagen.</p>
      <h3 id="-6-2-utlysning">§ 6.2 Utlysning</h3>
      <p>Utlysning av stormöte skall ske senast två veckor innan stormötesdagen. Utlysning skall ske på sådant sätt så att samtliga medlemmar kan ta del av den.</p>
      <h3 id="-6-3-protokoll">§ 6.3 Protokoll</h3>
      <p>Föreningen skall vid sina samtliga möten föra protokoll. Dessa skall finnas tillgängliga för medlemmarna senast två veckor efter mötesdag. Protokoll skall justeras av mötesordföranden jämte minst en, av mötet utsedda justerare.</p>
      <h2 id="-7-storm-te">§ 7 STORMÖTE</h2>
      <h3 id="-7-1-rsm-te">§ 7.1 Årsmöte</h3>
      <p>Tillsammans med föreningsmöten utgör årsmötet föreningens högsta beslutande organ. Årsmötet hålls en gång per år, dock senast i februari. Vid årsmötet skall minst följande ärenden behandlas:</p>
      <ul>
        <li>Fastslå verksamhetsplan.</li>
        <li>Fastställa medlemsavgiften.</li>
        <li>Fastställa budget för kommande räkenskapsår.</li>
        <li>Utse två firmatecknare för föreningen ur styrelsen.</li>
        <li>Val av valberedning.</li>
        <li>Fastställa balans- och resultaträkning för föregående räkenskapsår.</li>
        <li>Fastställande av verksamhetsberättelse för avgående styrelse.</li>
        <li>Redovisning av revisionsberättelserna.</li>
        <li>Beslut om ansvarsfrihet för den avgående styrelsen.</li>
      </ul>
      <h3 id="-7-2-f-reningsm-te">§ 7.2 Föreningsmöte</h3>
      <p>Föreningsmöte utöver årsmöte kan utlysas av styrelsen eller när minst 10 aktiva medlemmar så kräver. Förutom årsmöte skall minst ett föreningsmöte hållas varje termin.</p>
      <h3 id="-7-3-valm-te">§ 7.3 Valmöte</h3>
      <p>Föreningsmöte med val, s.k valmöte, skall hållas senast en månad innan årsmötet. Vald styrelse tillträder efter årsmötets slut.</p>
      <p>Besluta om antal övriga ledamöter i styrelsen, utöver sammansättning enligt §9.2 (dock minst 1st)</p>
      <ul>
        <li>Val av styrelse</li>
        <li>Val av två revisorer som skall redovisa en revisionsberättelse gällande nästkommande verksamhetsår.</li>
        <li>Val av verksamhetsrevisor som skall redovisa en revisionsberättelse gällande nästkommande verksamhetsår.</li>
      </ul>
      <h3 id="-7-4-storm-tets-justering">§ 7.4 Stormötets justering</h3>
      <p>På stormöte skall jämte mötesordförande två justerare tillika rösträknare utses.</p>
      <h3 id="-7-5-m-teshandlingar">§ 7.5 Möteshandlingar</h3>
      <p>Möteshandlingarna för stormöte skall vara samtliga medlemmar tillgängliga både elektroniskt och i pappersform senast 72 timmar före mötets start.</p>
      <h2 id="-8-valordning">§ 8 VALORDNING</h2>
      <h3 id="-8-1-vals-tt">§ 8.1 Valsätt</h3>
      <p>Styrelsen väljs årligen på föreningens valmöte med slutet val. Alla poster ställs alltid mot vakans.</p>
      <h3 id="-8-2-r-str-tt">§ 8.2 Rösträtt</h3>
      <p>Rösträtt tillfaller medlemmar enligt §5. Sittande styrelse äger ej rösträtt vid stormöte.</p>
      <h3 id="-8-3-valbarhet">§ 8.3 Valbarhet</h3>
      <p>Varje aktiv medlem är valbar till styrelsen om personen är myndig.</p>
      <h3 id="-8-4-nominering-av-kandidat">§ 8.4 Nominering av kandidat</h3>
      <p>Nomineringsrätt tillfaller varje medlem.</p>
      <h3 id="-8-5-registreringstid">§ 8.5 Registreringstid</h3>
      <p>Registreringstiden utgår en vecka före valdagen. Valberedningen kan förlänga registreringstiden för en enskild post om kandidat saknas.</p>
      <h2 id="-9-styrelse">§ 9 STYRELSE</h2>
      <h3 id="-9-1-allm-nt">§ 9.1 Allmänt</h3>
      <p>Styrelsen utgör SPIIKs beredande och verkställande organ. Mandatperioden för styrelseuppdrag inom föreningen är ett år. Styrelsen tillträder omedelbart efter årsmötet. Styrelsen övervakar föreningens intressen, samt kontrollerar att dessa ej strider emot föreningens stadgar. Styrelsen beslutar i de frågor som behandlas mellan stormöten. Styrelsen är beslutsför då minst hälften, inkluderat ordförande eller vice ordförande, är närvarande. Beslut fattas med enkel majoritet. Ordförande har utslagsröst.</p>
      <h3 id="-9-2-sammans-ttning">§ 9.2 Sammansättning</h3>
      <p>Styrelsen skall bestå av ordförande, vice ordförande, kassör, sekreterare, sexmästare och av valmötet antalet fastställda ledamöter. Jämn fördelning mellan kön, årskurser och utbildningar bör eftersträvas.</p>
      <h3 id="-9-3-arbetsuppgifter">§ 9.3 Arbetsuppgifter</h3>
      <p>Det åligger styrelsen att:</p>
      <ul>
        <li>Leda föreningens verksamhet.</li>
        <li>Företräda medlemmarna.</li>
        <li>Förvalta föreningens medel och tillgångar.</li>
        <li>Utlysa stormöte.</li>
        <li>Förbereda dagordning och upprätta föredragningslista för stormöte.</li>
        <li>Bereda ärenden som skall behandlas vid stormöte.</li>
        <li>Verkställa av stormöte fattade beslut.</li>
        <li>Upprätta verksamhetsberättelse och verksamhetsplan.</li>
        <li>Utfärda instruktioner för utskott/arbetsgrupper.</li>
      </ul>
      <h3 id="-9-4-delegering">§ 9.4 Delegering</h3>
      <p>Styrelsen må uppdra åt utskott/arbetsgrupp att i visst ärende eller viss grupp av ärenden fatta beslut å styrelsens vägnar.</p>
      <h3 id="-9-5-besv-r">§9.5 Besvär</h3>
      <p>Besvär mot av styrelsen fattat beslut framföres till stormöte i form av motion.</p>
      <h2 id="-10-utskott-och-arbetsgrupper">§ 10 UTSKOTT OCH ARBETSGRUPPER</h2>
      <p>Utskott och arbetsgrupper tillsätts genom styrelsens försorg. Sammankallande inom utskott och arbetsgrupper skall vara ledamot i föreningens styrelse.</p>
      <h2 id="-11-valberedning">§ 11 VALBEREDNING</h2>
      <p>Valberedningen skall bestå av minst två, max fem personer och har till uppgift att föreslå om- eller nyval av styrelseledamöter och revisorer till nästkommande verksamhetsår. Valberedningens förslag skall överlämnas till styrelsen senast sex dagar innan mötesdagen. Valberedningen ansvarar även för att eventuella vakanta valbara poster fylls.</p>
      <h2 id="-12-ekonomiska-revisorer">§ 12 EKONOMISKA REVISORER</h2>
      <p>Styrelsens förvaltning och föreningens räkenskaper skall årligen granskas av två på valmötet utsedda revisorer. Det åligger revisorerna att löpande kontrollera de ekonomiska transaktionerna samt att tillkalla experthjälp, vid behov eller om så påkallas, för att granska föreningens räkenskaper. Revisorerna skall senast på årsmötet avge berättelse över sin granskning.</p>
      <h2 id="-13-verksamhetsrevisor">§ 13 VERKSAMHETSREVISOR</h2>
      <h3 id="-13-1-uppgifter">§ 13.1 Uppgifter</h3>
      <p>Verksamhetsrevisor skall ägna uppmärksamhet åt samt stödja föreningens verksamhet. Verksamhetsrevisor skall därvid hållas underrättad om föreningens verksamhet och har rätt att ta del av föreningens protokoll och övriga handlingar.</p>
      <h3 id="-13-2-val">§ 13.2 Val</h3>
      <p>Verksamhetsrevisor väljs av valmötet för en tid av ett verksamhetsår.</p>
      <h3 id="-13-3-r-ttigheter">§ 13.3 Rättigheter</h3>
      <p>Verksamhetsrevisor äger närvaro-, yttrande- samt förslagsrätt vid sammanträde i föreningens samtliga organ.</p>
      <h3 id="-13-4-ligganden">§ 13.4 Åligganden</h3>
      <p>Verksamhetsrevisor åligger de uppgifter som regleras i denna stadga.</p>
      <h2 id="-14-stadgar">§ 14 STADGAR</h2>
      <h3 id="-14-1-f-rslag-till-ndring">§ 14.1 Förslag till ändring</h3>
      <p>Förslag till ändring i dessa stadgar behandlas av stormöte och får, förutom av styrelsen, väckas av aktiv/passiv medlem i SPIIK i form av motion.</p>
      <h3 id="-14-2-ndring-av-stadgar">§ 14.2 Ändring av stadgar</h3>
      <p>För ändring av dessa stadgar, utom 14.2 vilken endast kan ändras om samtliga röstberättigade stormötesdeltagare är överens därom, krävs beslut på två stormöten med enkel majoritet. Mellan de båda mötena måste minst en månad förflyta. Ändring av stadgar får endast ske på proposition eller motion avseende berörda stadgar eller stadgar som direkt påverkas av propositionen/motionen.</p>
      <h2 id="-15-uppl-sning">§ 15 UPPLÖSNING</h2>
      <p>För att föreningen skall kunna upplösas krävs framställan till föreningsmöte eller årsmöte. För att upplösningen skall kunna verkställas krävs beslut av två stormöten, dock med minst ett års mellanrum. Beslut skall fattas med minst två tredjedelars majoritet av de avlagda rösterna. Vid upplösning skall föreningens medel och tillgångar förvaltas av Linnékåren i Kalmar till dess att en motsvarande organisation har upprättats.</p>
    </div>
  </section>
);

export default Bylaws;
