import { Link } from 'react-router-dom';
import Banner from '../components/Banner';
import Split from '../components/Split';
import { nollningsYear } from '../utils/date';

const sections = {
  welcome: {
    title: 'Välkommen',
    text: 'Vi vill hälsa dig välkommen till SPIIKs egna hemsida! Här går det att hitta information om vilka SPIIK är, vilka de som sitter i styrelsen är, vad vi jobbar med samt annan information som kan vara bra att veta för både blivande och nuvarande studenter.',
    imgUrl: './start.jpg',
    imgAlt: 'nollningsstab 2020',
  },
  whoAreWe: {
    title: 'Vilka är vi?',
    text: 'Föreningens namn är Studentföreningen Prima Ingenjörer I Kalmar (SPIIK), och har sitt säte i Kalmar. Vi är en utbildningsförening under Linnéuniversitetets studentkår, Linnékåren. SPIIKs viktigaste uppgift är att främja sammanhållningen bland oss studenter som läser mjukvaruteknik, webbprogrammering och interaktionsdesign på Linnéuniversitetet i Kalmar, men också att ständigt arbeta för våra medlemmars intressen på Universitet och på arbetsmarknaden.',
  },
  info: {
    one: {
      title: 'Söker du bostad?',
      text: 'Har du bilvit antagen till campus och behöver ett boende? Super! Här hittar du en lista med olika hyresvärdar för studenter.',
    },
    two: {
      title: `Nollning ${nollningsYear()}`,
      text: 'Är du ny student och är nyfiken över hur introduktionen/ nollningen går till i SPIIK går det att läsa mer om kommande nollning här.',
    },
    three: {
      title: 'SPIIK medlemskap',
      text: 'Vad innebär det att vara medlem i SPIIK? Vad kostar ett medlemskap? Hur kan jag bli medlem i SPIIK? Här hittar ni svaret på dessa frågor.',
    },
    four: {
      title: 'Ovven den är röd',
      text: 'Vi i SPIIK klär oss bäst i rött och därför ser ni oss klädda i den röda ovven. Läs mer om den röda overallen och vart du kan få tag på en alldeles egen ovve.',
    },
  },
};

const {
  welcome, whoAreWe, info,
} = sections;

const Start = () => (
  <>
    <section>
      <Banner />
    </section>
    <div className="container">
      <section>
        <Split>
          <div>
            <h2>{welcome.title}</h2>
            <p>{welcome.text}</p>
          </div>
          <div>
            <img src={welcome.imgUrl} alt={welcome.imgAlt} />
          </div>
        </Split>
      </section>
      <section>
        <Split>
          <img src="/ovveinvigning.jpg" alt="ovveinvigning" />
          <div>
            <h2>{whoAreWe.title}</h2>
            <p>{whoAreWe.text}</p>
          </div>
        </Split>
      </section>
      <section>
        <Split>
          <div>
            <h2>{info.one.title}</h2>
            <div>
              <p>{info.one.text}</p>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="styled-link"
                to="/boende"
              >
                Läs mer
              </Link>
            </div>
          </div>
          <div>
            <h2>{info.two.title}</h2>
            <div>
              <p>{info.two.text}</p>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="styled-link"
                to="/introduktion"
              >
                Läs mer
              </Link>
            </div>
          </div>
          <div>
            <h2>{info.three.title}</h2>
            <div>
              <p>{info.three.text}</p>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="styled-link"
                to="/medlemskap"
              >
                Läs mer
              </Link>
            </div>
          </div>
          <div>
            <h2>{info.four.title}</h2>
            <div>
              <p>{info.four.text}</p>
              <Link
                onClick={() => window.scrollTo(0, 0)}
                className="styled-link"
                to="/overall"
              >
                Läs mer
              </Link>
            </div>
          </div>
        </Split>
      </section>
    </div>
  </>
);

export default Start;
