import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import {
  useEffect, useState, useCallback, useRef,
} from 'react';

import links from '../data/links';

import CustomNavLink from './CustomNavLink';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const node = useRef(null);

  const clickHandler = useCallback((e: MouseEvent) => {
    if (!(node.current! as any).contains(e.target)) {
      if (isOpen) {
        setIsOpen(!isOpen);
      }
    }
  }, [node.current]);

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', clickHandler);
    } else {
      document.removeEventListener('click', clickHandler);
    }

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  }, [isOpen]);

  return (
    <header>
      <div className="header-container">
        <h1 className="logo">SPIIK</h1>
        <span className="hamburger">
          <IconContext.Provider value={{ size: '20' }}>
            { isOpen
              ? <FaTimes onClick={() => setIsOpen(!isOpen)} />
              : <FaBars onClick={() => setIsOpen(!isOpen)} />}
          </IconContext.Provider>
        </span>
        <nav ref={node} className={`${isOpen ? 'nav-mobile' : 'nav-desktop'}`}>
          <ul className="nav-links text-center">
            {links.map((l) => (
              <CustomNavLink
                key={l.id}
                status={isOpen}
                onClick={() => {
                  window.scrollTo(0, 0);
                  setIsOpen(!isOpen);
                }}
                exact={l.exact}
                to={l.path}
              >
                {l.title}
              </CustomNavLink>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
