import styled from 'styled-components';

const Logo = styled.img.attrs(() => ({
  src: '/logo.png',
  alt: 'Studentföreningen Prima Ingengörer I Kalmar Logo',
}))`
  width: 15rem;
  display: inline-block;
`;

const BannerContainer = styled.div.attrs(() => ({
  alt: 'Studentföreningen Prima Ingengörer I Kalmar Logo',
}))`
  padding: 1em 0;
  text-align: center;
`;

const BannerH1 = styled.h1`
   margin-bottom: 1em;
`;

const Banner = () => (
  <BannerContainer>
    <BannerH1>
      Studentföreningen
      {' '}
      <span className="text-accent">
        Prima Ingenjörer
      </span>
      {' '}
      I Kalmar
    </BannerH1>
    <Logo />
  </BannerContainer>
);

export default Banner;
