import { v4 as uuid } from 'uuid';

export type sponsor = {
  id: string;
  info: string;
  url: string;
  urlTxt: string;
  imgUrl: string;
  imgAlt: string;
};

export const sponsors: sponsor[] = [
  {
    id: uuid(),
    url: 'https://advitum.se/',
    urlTxt: 'advitum.se',
    info: 'På Advitum erbjuder vi tjänster, produkter, service och utbildning inom IT-säkerhet och IT-infrastruktur till kunder inom både privat och offentlig sektor över hela landet. Vårt stora fokus på kundnöjdhet, affärsnytta och tillgänglighet gör oss till det självklara valet som IT-partner.',
    imgUrl: './sponsors/advitum.png',
    imgAlt: 'Advitum',
  },
  {
    id: uuid(),
    url: 'https://www.jmhardesign.com/',
    urlTxt: 'jmhardesign.com',
    info: 'JM Hårdesign skapar den personliga och kreativa frisyren åt dig. Vi sätter alltid kunden i första hand och är måna om kundens önskemål.',
    imgUrl: './sponsors/JM.png',
    imgAlt: 'JM-hardesign',
  },

];
