import React from 'react';

type Props = {
  two3070?: boolean,
  children:
  | React.ReactChild
  | React.ReactChild[];
}

const Split = ({ two3070, children }: Props) => (
  <div className={two3070 ? 'split split-30-70' : 'split'}>
    {React.Children.map(children, (child) => <div>{child}</div>)}
  </div>
);

Split.defaultProps = {
  two3070: false,
};

export default Split;
