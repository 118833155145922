import { v4 as uuid } from 'uuid';

export type BoardMember = {
  id: string;
  title: Titles;
  img: string;
  alt: string;
  mail: MailAddresses;
  name: string;
  program: string;
  message: string;
  quote: string;
  merit: string;
  game: string;
};

const spiikFullName = 'Studentföreningen Prima Ingenjörer I Kalmar';
export const boardMail = 'styrelsen@spiik.com';

export enum Names {
  PRESIDENT = 'Oscar Borgström',
  VICE_PRESIDENT = 'Edison Roldan Fröding',
  SEXMASTER = 'Domino Möllesand',
  SSUA = 'Elias Michalsky',
  TREASURER = 'Noa Cluer',
  VICE_TREASURER = 'Thyra Schillberger',
  SECRETARY = 'Emma Nilsson',
  INFO = 'Elsa Gas Wikström',
  CORPS_HOUSE = 'Mohamed Hussein',
  KAJSA = 'Kajsa'
}

export enum Titles {
  PRESIDENT = 'Ordförande',
  VICE_PRESIDENT = 'Vice Ordförande',
  SEXMASTER = 'Sexmästare',
  SSUA = 'SSUA',
  TREASURER = 'Kassör',
  VICE_TREASURER = 'Vice Kassör',
  SECRETARY = 'Sekreterare',
  INFO = 'Informationsansvarig',
  CORPS_HOUSE = 'Kårhusansvarig',
  KAJSA = 'The Big Boss'
}

export enum MailAddresses {
  PRESIDENT = 'ordf@spiik.com',
  VICE_PRESIDENT = 'viceordf@spiik.com',
  SEXMASTER = 'sexmaster@spiik.com',
  SSUA = 'vordfuu@spiik.com',
  TREASURER = 'kassor@spiik.com',
  VICE_TREASURER = 'vkassor@spiik.com',
  SECRETARY = 'sekreterare@spiik.com',
  INFO = 'socialamedier@spiik.com',
  CORPS_HOUSE = 'karhus@spiik.com',
  KAJSA = 'styrelsen@spiik.com'
}

enum Programs {
  WP = 'Webbprogrammerare',
  MT = 'Mjukvaruteknik',
  ID = 'Interaktionsdesigner',
  TK = 'Inget speciellt'
}

export const board: BoardMember[] = [
  {
    id: uuid(),
    title: Titles.PRESIDENT,
    img: './board-members/ordf.jpg',
    alt: `${spiikFullName} ordförande`,
    name: Names.PRESIDENT,
    mail: MailAddresses.PRESIDENT,
    program: Programs.MT,
    message: 'Sätt flera larm når du har tenta.',
    quote: '"I knew exactly what to do. But in a much more real sense, I had no idea what to do."',
    merit: 'Bara försovit mig till en tenta i år',
    game: 'The Witcher 3',
  },

  {
    id: uuid(),
    title: Titles.VICE_PRESIDENT,
    img: './board-members/vice-ordf.jpg',
    alt: `${spiikFullName} vice ordförande`,
    name: Names.VICE_PRESIDENT,
    mail: MailAddresses.VICE_PRESIDENT,
    program: Programs.ID,
    message: 'Öl innehåller väldigt lite vitaminer, därför måste man dricka många!',
    quote: '"Att göra alla nöjda är rent omöjligt men att gå alla på nerverna är lätt!"',
    merit: 'Bättre än Domino på ALLA spel',
    game: 'God of War',
  },

  {
    id: uuid(),
    title: Titles.SEXMASTER,
    img: './board-members/sexmastare.jpg',
    alt: `${spiikFullName} sexmästare`,
    name: Names.SEXMASTER,
    mail: MailAddresses.SEXMASTER,
    program: Programs.ID,
    message: 'Ta en minttu eller två',
    quote: '"Det är ett problem för framtida mig"',
    merit: 'Klarade en lambo med 1/3 av en jack daniels flaska',
    game: 'Assasin\u0027s Creed Odyssey',
  },

  {
    id: uuid(),
    title: Titles.SSUA,
    img: './board-members/ssua.jpg',
    alt: `${spiikFullName} SSUA`,
    name: Names.SSUA,
    mail: MailAddresses.SSUA,
    program: Programs.MT,
    message: 'Underskatta inte grunkan.',
    quote: '”En monster om dagen är bra för magen.”',
    merit: 'Har klarat pogostuck',
    game: 'Minecraft',
  },

  {
    id: uuid(),
    title: Titles.TREASURER,
    img: './board-members/kassor.jpg',
    alt: `${spiikFullName} kassör`,
    name: Names.TREASURER,
    mail: MailAddresses.TREASURER,
    program: Programs.MT,
    message: 'Det är irriterande hur dåligt teori verkar fungera i verkligheten.',
    quote: '"If there\u0027s is a will there\u0027s a way"',
    merit: 'Halvfransk',
    game: 'Minecraft',
  },

  {
    id: uuid(),
    title: Titles.VICE_TREASURER,
    img: './board-members/vice-kassor.jpg',
    alt: `${spiikFullName} vice kassör`,
    name: Names.VICE_TREASURER,
    mail: MailAddresses.VICE_TREASURER,
    program: Programs.ID,
    message: 'Det e inte lätt när det e svårt',
    quote: '"Det där låter som ett du problem"',
    merit: 'Kan klunka 3 glas valfri dryck på 11 sekunder',
    game: 'Assassin\u0027s Creed Odyssey',
  },

  {
    id: uuid(),
    title: Titles.SECRETARY,
    img: './board-members/sekreterare.jpg',
    alt: `${spiikFullName} sekreterare`,
    name: Names.SECRETARY,
    mail: MailAddresses.SECRETARY,
    program: Programs.ID,
    message: 'Förlåt',
    quote: '"Minnen för livet!"',
    merit: 'Har lyckats skära mig på en handduk',
    game: 'Stardew Valley',
  },

  {
    id: uuid(),
    title: Titles.INFO,
    img: './board-members/information.jpg',
    alt: `${spiikFullName} informationsansvarig`,
    name: Names.INFO,
    mail: MailAddresses.INFO,
    program: Programs.WP,
    message: 'Sí, señor, efectos especiales, yeah, yeah, yeah. Sí, señor, una tentación, yeah, yeah, yeah',
    quote: '“Tú y yo a la fiesta. Tú y yo toda la noche. Tú y yo a la fiesta. Tú y yo.”',
    merit: 'Flörta med äldre gubbar i hopp om att de ska ge mig större dricks.',
    game: 'Desert Bus',
  },

  {
    id: uuid(),
    title: Titles.CORPS_HOUSE,
    img: './board-members/karhus.jpg',
    alt: `${spiikFullName} kårhusansvarig`,
    name: Names.CORPS_HOUSE,
    mail: MailAddresses.CORPS_HOUSE,
    program: Programs.MT,
    message: 'Du kan vänta på att göra inlämmningar tills sista sekunden men gör inte det.',
    quote: '"Nothing a cigarettes can\u0027t fix"',
    merit: 'Lämnar alla inlämningar försent :)',
    game: 'Sonic Adventure DX',
  },

  {
    id: uuid(),
    title: Titles.KAJSA,
    img: './board-members/kajsa.jpg',
    alt: `${spiikFullName} the big boss`,
    name: Names.KAJSA,
    mail: MailAddresses.KAJSA,
    program: Programs.TK,
    message: 'Jag är SPIIKs maskot, säg hej om du ser mig.',
    quote: '"I\u0027m back!"',
    merit: 'Varit med SPIIK sedan 1998-99',
    game: 'Firefighter Simulator',
  },

];
