import Cover from '../components/Cover';
import { covers } from '../data/covers';
import { MailAddresses } from '../data/board';
import programs from '../data/programs';

const { education, educationHelp } = covers;

const Education = () => (
  <>
    <section>
      <Cover imgUrl={education.imgUrl} title={education.title}>
        {education.text}
      </Cover>
    </section>
    <div className="container">
      {programs.map((p) => (
        // TODO: Remove styling as inline if possible..
        <section key={p.id} style={{ maxWidth: '60rem', margin: 'auto' }}>
          <div>
            <h2>{p.title}</h2>
            <h3>
              <a className="styled-link" href={p.url} target="_blank" rel="noopener noreferrer">{p.hp}</a>
            </h3>
            <p>{p.description}</p>
          </div>
        </section>
      ))}
      <section>
        <Cover imgUrl={educationHelp.imgUrl} title={educationHelp.title}>
          {educationHelp.text}
          <a className="styled-link" aria-label="SSUA Mail" href={`mailto:${MailAddresses.SSUA}`}>{MailAddresses.SSUA}</a>
        </Cover>
      </section>
    </div>
  </>
);

export default Education;
